import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import {UntypedFormGroup, FormControl,UntypedFormBuilder, Validators } from '@angular/forms';
import {SetApproverService} from './set-approver.service';
@Component({
  selector: 'app-set-approver',
  templateUrl: './set-approver.component.html',
  styleUrls: ['./set-approver.component.css']
})
export class SetApproverComponent implements OnInit {
  @Output() manager = new EventEmitter<string>();
  @Output() managerEmail = new EventEmitter<string>();
  //@Output('manager') manager:string;
  //@Input('managerEmail') managerEmail:string;
  @Input('devMode') devMode:Boolean;
  @Input('userName') userName:string;  
  @Input('approverListOnly') approverListOnly:Boolean;
  //@Input('company') company:string;
  company:string='';
  managersList:any[];
  submitted:any;
  validated:any;
  showApprover:boolean=true;
  setApproverForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder,private setApproverSvc:SetApproverService) { }

  ngOnInit() {
    this.setApproverForm=this.formBuilder.group({      
      rbApprover:  [null],
      ddlApprover:  [null]       
      });

      this.company=this.userName.substring(this.userName.indexOf('@')+1,this.userName.indexOf('.com'))
      if(this.approverListOnly==false){
        this.showApprover=true;
        if(this.company.toLowerCase().indexOf('ericsson') != -1){
          this.setApproverForm.value.rbApprover='ERICSSON';
          this.GetManagers('ERICSSON');
        }else{
          this.setApproverForm.value.rbApprover='CUSTOMERS';
          this.GetManagers('CUSTOMERS');
        }
      }else{
        this.showApprover=false;
        this.GetManagers('vodafone');
      }
  }
  GetManagers(approverValue){
    this.setApproverSvc.GetManagers(approverValue).subscribe(data=>{
      this.managersList=data.table;   
      if(this.devMode==true){
        this.managersList.push([{userName:this.userName}]);
      }
      
    });
  }
  GetSelectedManager(event){
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;   
    this.manager.emit(selectedOptions[selectedIndex].text);
    this.managerEmail.emit(selectedOptions[selectedIndex].value);
  }

}

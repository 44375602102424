
<form novalidate [formGroup]="requestViewForm">    
    <div class="row isr">
            <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">Request</div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block"> 
                    <div class="row">
                        <div class="col-lg-6">
                            <label>Type of request:</label>                                                        
                        </div>
                        <div class="col-lg-6">
                            <label>{{reqParentTypeDesc}}</label> 
                            <!--<select formControlName="requestType" class="selectTypeOfRequest" (change)="bindSubTypeOfRequest($event)" [ngClass]="{ 'is-invalid': submitted && validated.requestType.errors }">
                                <option selected value="null">Select</option>
                                <option *ngFor="let reqType of listTypeOfRequest" [value]="reqType.itmId">{{reqType.itmName}}</option> 
                            </select>
                       <div *ngIf="submitted && validated.requestType.errors" class="ValidationLabel">
                        <div *ngIf="validated.requestType.errors.required">Type of request is required.</div>
                       </div>-->  
                    </div>
                    </div>                     
                </div>                       
                </div>
                
                <div *ngIf="showSubTypeOfRequest" class="card card-primary">
                    <div class="card-block"> 
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Sub type of request:</label>
                            </div>
                            <div class="col-lg-6">
                               <!-- <select formControlName="requestSubType" class="selectTypeOfRequest" (change)="GetRequestDefinitions($event)" [ngClass]="{ 'is-invalid': submitted && validated.requestSubType.errors }"> 
                                    <option selected value="null">Select</option>
                                    <option *ngFor="let reqSubType of listSubTypeOfRequest" [value]="reqSubType.itmId">{{reqSubType.itmName}}</option> 
                                </select>
                                <div *ngIf="submitted && validated.requestSubType.errors" class="ValidationLabel">
                                    <div *ngIf="validated.requestSubType.errors.required">Sub type of request is required.</div>
                                   </div> -->
                                   <!--<input type="text" class="inputDescription" disabled="disabled" value="{{reqSubTypeDesc}}"/>-->
                                <label>{{reqSubTypeDesc}}</label> 
                            </div>
                        </div>                     
                    </div>                       
                    </div>
                    <!-- <div *ngIf="!reqDefinitionModel.oneTimeAllowed" class="card card-primary"> -->
                        <div class="card card-primary">
                        <div class="card-block"> 
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>Description of request:</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" formControlName="shortDesc" [ngClass]="{ 'is-invalid': submitted && validated.shortDesc.errors }" class="inputDescription">
                                    <div *ngIf="submitted && validated.shortDesc.errors" class="ValidationLabel">
                                        <div *ngIf="validated.shortDesc.errors.required">Description of request is required.</div>
                                       </div> 
                                </div>
                            </div>        
                            <div class="row" style="margin-top: 3px;">
                                <div class="col-lg-6">
                                    <label>Requested delivery date:</label>
                                </div>
                                <div class="col-lg-6">  
                                     <input type="date" style="margin-right: 2px;"  formControlName="requestedDeliveryDateControl" >                            
                                    <!--<input type="date" style="margin-right: 2px;"  [ngModel]="requestedDeliveryDate | date:'yyyy-MM-dd'" formControlName="requestedDeliveryDateControl" class="inputDeliveryDate">                           -->
                                    <img style="padding-bottom: 5px;"  title="{{requestedDeliveryDateNote}}"  src="assets/Images/info.png" >
                                </div>
                            </div>   
                        </div>                       
                        </div>
                        
                            
                        
                        <!-- <div *ngIf="reqDefinitionModel.oneTimeAllowed "> -->
                            <div>
                            <!--<div class="card card-primary">
                                <div class="card-block"> 
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label>Is this a one time request for 3rd party?</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="radio" formControlName="rbOneTimes" value="Yes" (change)="ChangeOneTime()">
                                            &nbsp;<label>Yes</label>&nbsp;&nbsp;
                                            <input type="radio" formControlName="rbOneTimes" value="No" (change)="ChangeOneTime()" >
                                            &nbsp;<label>No</label>&nbsp;      
                                        </div>
                                    </div>    
                                </div>                       
                                </div>
                                <div class="card card-primary">
                                    <div class="card-block"> 
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>One-time user</label>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Name:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeName"> 
                                            </div>
                                        </div>    
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Email address:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeEmailAddress"> 
                                            </div>
                                        </div> 
                                        <div class="row" style="margin-top: 3px;">
                                            <div class="col-lg-6">
                                                <label>Request description:</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input type="text" formControlName="oneTimeRequestDescription"> 
                                            </div>
                                        </div> 
                                    </div>                       
                                    </div>-->
                        </div> 
                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button type="submit">Menu</button>
                                    </div>
                                    <div class="col-lg-6" style="text-align: right;" >
                                        <button type="submit" (click)="GoToProjectRefandBudget()">{{btNext}}</button>
                                    </div>
                                </div>                                
                            </div>                       
                            </div>
            </div> 
    </div>
    </form>
   
  
import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup, FormControl,UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import {SharedService} from '../Shared/shared.service';
import { Router,ActivatedRoute } from '@angular/router';
import {RequestModel} from '../model/request.model';
import {RequestDefinitionsModel} from '../model/requestDefinitions.model';
import{userModel} from '../model/user.model';
import{LoginModel} from '../model/login.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-proj-ref-and-budget-view',
  templateUrl: './proj-ref-and-budget-view.component.html',
  styleUrls: ['./proj-ref-and-budget-view.component.css'],
  providers: [RequestModel,RequestDefinitionsModel,userModel,LoginModel]
})
export class ProjRefAndBudgetViewComponent implements OnInit {
  //reqModel:RequestModel;
  projectRefAndBudgetForm: UntypedFormGroup;
  submitted = false;  
  requestViewData:[any];
  relatedProjectText:string;
  constructor( private SpinnerService: NgxSpinnerService,
     private formBuilder: UntypedFormBuilder, 
     private sharedService:SharedService,
     private route:ActivatedRoute,
     private reqModel:RequestModel,
     private reqDefinitionModel:RequestDefinitionsModel,
     private userModel:userModel, 
     private loginModel:LoginModel,
     private cookieService: CookieService) { }

  ngOnInit() {     
    this.projectRefAndBudgetForm=this.formBuilder.group({      
      isPartOfProject:  [null, Validators.required],
      IsExistingEricssonProject: [null, Validators.required],
      projectNameRequest: ['', Validators.required],
      projectID: ['', Validators.required],
      ericssonProject: [null, Validators.required]
      });
      this.ConditionalValidator();
      this.reqDefinitionModel=window.history.state.reqDefinitions;
      //this.userModel=window.history.state.userModel;
      this.reqModel= window.history.state.reqData;
      //this.loginModel=window.history.state.loginModel;      
      var email =this.cookieService.get('userEmail'); //this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');
      console.log('email',email);
    let companyName= email.substring(email.indexOf('@')+1,email.indexOf('.com'))
     this.relatedProjectText="Please give the name/id of the related "+ companyName + " project:";
  }
  get validated() { return this.projectRefAndBudgetForm.controls; }

  ConditionalValidator() {
    this.projectRefAndBudgetForm.get('isPartOfProject').valueChanges
    .subscribe(value => {
      if(value=='Yes') {
        this.projectRefAndBudgetForm.get('IsExistingEricssonProject').setValidators(Validators.required)              
      } else {
        this.projectRefAndBudgetForm.get('IsExistingEricssonProject').clearValidators();
        this.projectRefAndBudgetForm.get('projectNameRequest').clearValidators();
          this.projectRefAndBudgetForm.get('projectID').clearValidators();
          this.projectRefAndBudgetForm.get('ericssonProject').clearValidators(); 
      }     
    });
    this.projectRefAndBudgetForm.get('IsExistingEricssonProject').valueChanges
    .subscribe(value => {
      if(value=='Yes') {
        this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('ericssonProject').setValidators(Validators.required);
      }          
        else if(value=='No') {        
          this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
          this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);  
          this.projectRefAndBudgetForm.get('ericssonProject').clearValidators(); 
        }      
    });
    this.projectRefAndBudgetForm.get('IsExistingEricssonProject').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('projectNameRequest').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('projectID').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('ericssonProject').updateValueAndValidity();
  }
  ChangeisPartOfProject(){    
  if(this.projectRefAndBudgetForm.get('isPartOfProject').value=='No')  {
    this.projectRefAndBudgetForm.get('IsExistingEricssonProject').setValue(null);
    this.projectRefAndBudgetForm.get('projectNameRequest').setValue('');
    this.projectRefAndBudgetForm.get('projectID').setValue('');
    this.projectRefAndBudgetForm.get('ericssonProject').setValue(null);
    }
  }
  ChangeIsExistingEricssonProject(){    
   // if(this.projectRefAndBudgetForm.get('IsExistingEricssonProject').value=='No')  {     
      this.projectRefAndBudgetForm.get('ericssonProject').setValue(null);
    //  }
    }
  GoToNextView(){
    this.submitted = true;       
    if (this.projectRefAndBudgetForm.invalid) {            
      return;
  }
  else{
    if(this.projectRefAndBudgetForm.value.IsExistingEricssonProject=='Yes')
        this.reqModel.isPartOfProject=true;
    else
        this.reqModel.isPartOfProject=false;

    this.reqModel.projectNameRequest=this.projectRefAndBudgetForm.value.projectNameRequest;
    this.reqModel.projectID=this.projectRefAndBudgetForm.value.projectID;

    if(this.projectRefAndBudgetForm.value.ericssonProject=='Yes')
        this.reqModel.ericssonProject=true;  
    else
        this.reqModel.ericssonProject=false;  

        console.log('modeltest',this.reqDefinitionModel);       
      
    this.sharedService.gotoNextView('sendRequestView',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);    
  }
  }
  GoToPreviousView(){
    this.sharedService.gotoNextView('reqView',this.reqModel,this.reqDefinitionModel,this.userModel,this.loginModel);  
  }
}


<form novalidate [formGroup]="sendRequestForm">
    
    <div class="row isr" >
            <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header1">
                    <div class="card-title">{{requestType}}</div>
                    <div *ngIf="showSubTypeOfRequest">{{requestSubType}}</div>
                    <!--<div class="card-title">{{requestType}}</div>-->
                    <!--<div class="card-title">{{requestSubType}}</div>-->
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block"> 
                    <div class="row">
                        <div class="col-lg-6">
                            <label>Download the template and save it locally:</label>
                        </div>
                        <div class="col-lg-3">
                            <button type="submit" (click)="GetTemplate()">Download Template</button>
                    </div>
                    <div class="col-lg-1">
                       <!--<div contenttype="template" style="margin-top: 6px;" matTooltip=          
                        "Please note that incomplete templates will be rejected.
                        <br><br>After pressing the 'Download template' 
                        button you must open or save the template to your local drive (or desktop).
                        <br><font color='red'>
                            Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step." matTooltipPosition="after">
              <img src="assets/Images/info.png" />  </div>-->
              <div contenttype="template" style="margin-top: 6px;" [matTooltip]="tooltipHtml"
              matTooltipPosition="above">
              <img src="assets/Images/info.png" />  </div>
           <!--  <img src="assets/Images/info.png" 
     [matTooltip]="tooltipHtml"
     matTooltipPosition="above">-->
           
                </div>
                    </div>                     
                </div>                       
                </div>
                <div class="card card-primary">
                    <div class="card-block"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-12">
                                    <label>Open the locally saved template and fill in all fields.</label>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                    <label>Make sure you have answered all questions completely.</label>
                                </div>
                                </div>
                                <div style="margin-top: 15px;" class="row">
                                    <div class="col-lg-12">
                                    <label style="font-weight:800;">Save the filled in template.</label>
                                </div>
                                </div>                                
                            </div>   
                        </div>                     
                    </div>                       
                    </div>
                    <div class="card card-primary">
                        <div class="card-block"> 
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>Upload the filled in and saved template:</label>
                                </div>                               
                            </div>        
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card card-primary">
                                        <div class="card-block">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <input type="file" (change)="handleFileInput($event.target.files)" formControlName="templateFile" >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>   
                        </div>                       
                        </div>
                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label>You can/may supply additional information:</label>
                                    </div>  
                                    <div class="col-lg-8">
                                        <textarea formControlName="addInfo"  rows="5" cols="75"></textarea>
                                    </div>                             
                                </div>   
                            </div>
                        </div>
                        <div *ngIf="!authorized">
                            <app-set-approver  (manager)="getManager($event)" (managerEmail)="getManagerEmail($event)" [userName]="userName" [devMode]="devMode" [approverListOnly]="approverListOnly"  ></app-set-approver>
                        </div>
                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label>Send the request:</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <button type="submit" (click)="SendRequest()" >Send</button>
                                    </div>
                                    
                                </div>                                
                            </div>                       
                            </div>
            </div>    
    </div>
   
    </form>
import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
// import 'rxjs/add/operators/map';
// import 'rxjs/add/operators/catch';
import {SharedService} from '../Shared/shared.service';
import {RequestModel} from '../model/request.model';
import {sampleReq} from '../model/request.model';
@Injectable({
  providedIn: 'root'
})
export class SendRequestViewService {

  constructor(private _http: HttpClient,private sharedService:SharedService) {}
  GetTemplate(req:RequestModel):Observable<any>{      
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/sendRequest/PostTemplate/' , req);
  };
   //formData: FormData = new FormData();  
 

   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })}
  //AddRequest(req:RequestModel,formData):Observable<any>{     
    AddRequest(templatefile,formData):Observable<any>{     
    //const formData: FormData = new FormData();  
    const HttpUploadOptions = {
        headers: new HttpHeaders({ "Accept": "application/json" })
      }
    //formData.append('templatefile',req.templateFile);
    return this._http.post<any[]>(this.sharedService.API_URL + '/api/ISR/request/PostRequestData/', formData,HttpUploadOptions);
  };
 
}

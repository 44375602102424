<form novalidate [formGroup]="setApproverForm">
    <div class="row isr">
            <div class="col-lg-12">           
               
                <div class="card card-primary">
                    <div class="card-block"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <label>You are not a pre-authorized requestor for this request type.</label>
                            </div>
                        </div>  
                        <div class="row">
                            <div class="col-lg-3">
                                <label>Please specify your approver:</label>
                            </div>
                            <div *ngIf="showApprover" class="col-lg-5">
                                <input type="radio" formControlName="rbApprover" value="ERICSSON" (change)="GetManagers('ERICSSON')">
                                            &nbsp;<label>ERICSSON</label>&nbsp;&nbsp;
                                            <input type="radio" formControlName="rbApprover" value="CUSTOMERS" (change)="GetManagers('CUSTOMERS')" >
                                            &nbsp;<label>CUSTOMERS</label>&nbsp;                                 
                            </div>
                            <div class="col-lg-3">
                                <!-- <select formControlName="ddlApprover" class="selectApprover" (change)="GetSelectedManager($event)" [ngClass]="{ 'is-invalid': submitted && validated.requestSubType.errors }">  -->
                                    <select formControlName="ddlApprover" class="selectApprover" (change)="GetSelectedManager($event)"> 
                                    <option selected value="null">Select</option>
                                    <option *ngFor="let managers of managersList" [value]="managers.email">{{managers.name}}</option> 
                                </select>
                            </div>
                        </div>                     
                    </div>                       
                    </div>
                   
                     
                    
            </div>    
    </div>
   
    </form>

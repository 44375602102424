import { Component, OnInit } from '@angular/core';
import {UntypedFormGroup, FormControl,UntypedFormBuilder, Validators, FormGroupName } from '@angular/forms';
import {SendRequestViewService} from '../send-request-view/send-request-view.service';
import {SharedService} from '../Shared/shared.service';
import {RequestModel} from '../model/request.model';
import {sampleReq} from '../model/request.model';
import {RequestDefinitionsModel} from '../model/requestDefinitions.model';
import{userModel} from '../model/user.model';
import{LoginModel} from '../model/login.model';
import {StatusList,StatusNames,TypeOfRequests} from '../model/enum.model';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-send-request-view',
  templateUrl: './send-request-view.component.html',
  styleUrls: ['./send-request-view.component.css'],
  providers: [RequestModel,RequestDefinitionsModel,userModel,LoginModel]
})
export class SendRequestViewComponent implements OnInit {  
  sendRequestForm: UntypedFormGroup;
  formData: FormData = new FormData();
  submitted = false;
  fileBytes: Uint8Array | null = null;
  templateFile;
  requestType:String='';
  requestSubType:String='';

  comapny:string='';
  userName:String='';
  devMode:Boolean=false;
  approverListOnly:Boolean=false;
  manager:String='';
  managerEmail:String='';
  authorized:Boolean=false;
  ISTCookieValue: string;
  showSubTypeOfRequest:boolean = true;

  title = 'appBootstrap';  
  closeResult: string;
  // Safe HTML content
  tooltipHtml: SafeHtml;
  
  constructor(private formBuilder: UntypedFormBuilder, 
    private sendRequestViewSvc: SendRequestViewService,
     private sharedService: SharedService,
     private reqModel:RequestModel, 
     private userModel:userModel, 
     private loginModel:LoginModel,
     private reqDefinitionModel:RequestDefinitionsModel,
     private cookieService: CookieService,
     private sanitizer: DomSanitizer) {
      // Define HTML content and sanitize it
    this.tooltipHtml = this.sanitizer.bypassSecurityTrustHtml("Please note that incomplete templates will be rejected.<br><br>After pressing the 'Download template' button you must open or save the template to your local drive (or desktop).<br><font color='red'> Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step.");
      }

  ngOnInit() {
    this.sendRequestForm=this.formBuilder.group({
      downloadFile: [''],
      templateFile: [''],
      addInfo:['']
      });      
      //this.loginModel=window.history.state.loginModel;
      this.userModel=window.history.state.userModel;
      this.reqModel= window.history.state.reqData;
      this.requestType=this.reqModel.requestTypeText;
      this.requestSubType=this.reqModel.requestSubTypeText;
      this.reqDefinitionModel=window.history.state.reqDefinitions;

      this.authorized=this.reqModel.authorized;
      if(this.reqModel.isParentRequest)
      {
        this.showSubTypeOfRequest = false;
      }
     /* this.userName=this.userModel.email;
      this.devMode=this.userModel.devMode;*/
     
      /*this.ISTCookieValue = this.cookieService.get('ISTCookie2');
      this.userName = this.cookieService.get('userEmail');
      this.devMode =  this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("devmode"), this.ISTCookieValue.indexOf("&")).replace("devmode=", '') == 'true' ? true : false;
      */
      this.approverListOnly=false;
  }
  getManager(message: string) {
    this.manager = message;
  }
  getManagerEmail(message: string) {
    this.managerEmail = message;
  }
  GetTemplate(){
    var url = this.sharedService.API_URL + "GetFile.ashx?file=" + "temp\\DownloadTemplate\\" + this.reqDefinitionModel.formFile;
    window.open(url);
  }
  handleFileInput(files: FileList){
    if(files.length > 0){
    this.templateFile = files.item(0);
    this.convertFileToBytes(this.templateFile);
    }
  }
  convertFileToBytes(file: File): void {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result) {
        // Convert ArrayBuffer to Uint8Array
        this.fileBytes = new Uint8Array(reader.result as ArrayBuffer);
        console.log('File as bytes:', this.fileBytes);
      }
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
  }
  // open(content) {
  //   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return  `with: ${reason}`;
  //   }
  // }
  SendRequest(){
    this.submitted = true;
    if (this.sendRequestForm.invalid) {
      return;
  }
  else{
    // this.reqModel.tor= window.history.state.formData.requestSubType.substring(0,2);
    // this.reqModel.subtor= window.history.state.formData.requestSubType.substring(2,4);

    // //to do..
    
  /* this.reqModel.requestType=window.history.state.formData.requestType; 
     this.reqModel.requestSubType=window.history.state.formData.requestSubType; 
     this.reqModel.shortDesc=window.history.state.formData.shortDesc; 
     this.reqModel.requestedDeliveryDate=window.history.state.formData.requestedDeliveryDate; 
    */
    // this.reqModel.isPartOfProject=window.history.state.formData.isPartOfProject;
    // this.reqModel.projectNameRequest=window.history.state.formData.projectNameRequest;
    // this.reqModel.projectID=window.history.state.formData.projectID;
    // this.reqModel.ericssonProject=window.history.state.formData.ericssonProject;

    this.reqModel.addInfo=this.sendRequestForm.value.addInfo;
    //this.reqModel.templateFile=this.templateFile;//this.fileBytes;
    this.reqModel.templateFileType=this.templateFile.name.split('.').pop();//this.templateFile.type;    
    this.reqModel.templateFileName=this.templateFile.name;
   // this.reqModel.=this.templateFile.;
    this.reqModel.task=1;

    this.reqModel.requestCreatedOn=new Date();
    //to do..
    //this.reqModel.requestCreatedBy='komal.gawande@ericsson.com';
    this.reqModel.requestCreatedBy=this.userModel.userID;
    // this.reqModel.requestTTL=window.history.state.formData.requestTTL;
    // this.reqModel.hasApprovers=window.history.state.formData.hasApprovers; 
    if ( this.reqModel.requestAcceptance)
    {
      this.reqModel.status=StatusList.Requesting;
    }
    else
    {
      this.reqModel.status = StatusList.Assigned
    }
     this.reqModel.userName = this.userModel.email;
     this.reqModel.devMode = this.userModel.devMode;
     this.reqModel.fullUserName = this.userModel.fullName;
    
        //to do.. vembu
     this.reqModel.requestAcceptedBy = null;
  //    this.reqModel.requestNumber=''
  //    var _formData = {
  //     status:  this.reqModel.status,      
  // };  
    
    this.formData.append('formData', JSON.stringify(this.reqModel));
    this.formData.append('userData', JSON.stringify(this.userModel));
    this.formData.append('templatefile',this.templateFile);    
    //this.sendRequestViewSvc.AddRequest(this.reqModel,this.formData).subscribe(data=>{
    this.sendRequestViewSvc.AddRequest(this.templateFile,this.formData).subscribe(data=>{
      if(data!=""){
        alert('Request created successfully');
      }else{
        alert('wrong');
      }         
    });
  }
}
}
